export default function editObjective(){
	return `mutation WriteObjectives(
		$_id: String
		$objective: String
		$weight: Float
		$goal: String
		$title: String
		$isStrategicObjective: Boolean
	) {
		writeObjectives(
			_id: $_id
			objective: $objective
			weight: $weight
			goal: $goal
			title: $title
			isStrategicObjective: $isStrategicObjective
		)
	}`;
}
