const listKeyPositions = () => {
	return `query HRListKeyPositions(
		$subgroup: String!
	) {
		hrListKeyPositions(
			subgroup:$subgroup
		){
			_id
			name
		}
	}`;
}

export default listKeyPositions;
