const AppraisalPrinc = () => {
	return `mutation ManagerAppraisal(
		$appraisee_id: String
		$principles: [AppraisalInput]
	) {
		managerAppraisal(
			appraisee_id: $appraisee_id
			principles: $principles
		)
	}`;
}

export default AppraisalPrinc;
