const HReditAspirations = () => {
	return `mutation HREditAspirations(
		$appraisee_id: String!
		$medium_term: String
		$long_term: String
		$mobility: [String]
	) {
		hrEditAspirations(
			appraisee_id: $appraisee_id
			medium_term: $medium_term
			long_term: $long_term
			mobility: $mobility
		)
	}`
}

export default HReditAspirations;
