import Requester from "@global/components/Requester";
import listSubgroup from '@global/query/listSubgroup';
import listSubgroupCalibration from '@global/query/listSubgroupCalibration';

const Subgroup = {
	get: async (query, isCalibration) => {
		try {
			let action = isCalibration ? listSubgroupCalibration : listSubgroup;

			let res = await Requester({
				data: {
					query: action(query)
				}
			});

			return res.data.listSubgroup || res.data.hrListCalibrationSubGroup;
		} catch(err) {
			return [];
		}
	}
}

export default Subgroup;
