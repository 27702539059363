export default function managerDeleteObjective(){
	return `mutation ManagerDeleteObjective(
		$objective_id: String!
		$appraisee_id: String!
	) {
		managerDeleteObjective(
			objective_id: $objective_id
			appraisee_id: $appraisee_id
		)
	}`;
}
