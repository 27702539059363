import PMS from "@global/components/PMS";
import Requester from "@global/components/Requester";
import editAspirations from '@global/query/editAspirations';
import HReditAspirations from '@global/query/HReditAspirations';

const Aspirations = {
	get: async (id, profile) => {
		try {
			let user = await PMS.User.getUserQuery('performance {aspirations {year medium_term long_term mobility}}', profile, id);
			if(user) {
				let history = JSON.parse(localStorage.getItem("historyAspirationSelfAppraisal" + (id || "")) || "[]");
				let data = user.performance.aspirations;
				let currentData = data.find((item) => {
					return +item.year === PMS.Dates.currentYear
				});

				currentData =  {...currentData, ...history}

				return {
					data,
					currentData,
				}
			}
			else {
				return ({})
			}
		} catch(err) {
			return ({});
		}
	},
	editDataById: (value, key, data, _id) => {
		data[key] = value;

		localStorage.setItem(("historyAspirationSelfAppraisal" + (_id || "")), JSON.stringify(data));

		return data;
	},
	getSelfAppraisalQuery: async(aspirations, appraisee_id) => {
		try{
			let action = editAspirations;
			if(appraisee_id){
				action = HReditAspirations;
			}

			if(!aspirations.mobility || typeof(aspirations.mobility) !== "object"){
				aspirations.mobility = [];
			}
			else{
				aspirations.mobility = aspirations.mobility.filter((item) => {
					return item !== true || item !== false;
				});
			}


			let {data} = await Requester({
				data: {
					query: action(),
					variables: {
						appraisee_id,
						...aspirations
					}
				}
			});

			let result = data.editAspirations || data.hrEditAspirations;

			if(+result || result === "0"){
				return true;
			}
			else{
				return false;
			}
		} catch(err) {
			return false;
		}
	},
	finishSelfAppraisal: async(aspirations, userId) => {
		try{
			let result = await Aspirations.getSelfAppraisalQuery(aspirations, userId);

			if(result) {
				await PMS.Flows.getFinishFlowQuery("myProfile", userId, PMS.Flows.self, "Aspirations");

				Aspirations.cleanHistoryAspirationSelfAppraisal(userId);

				return true;
			}
			else {
				return false;
			}
		} catch(err) {
			return false;
		}
	},
	cleanHistoryAspirationSelfAppraisal: (userId) => {
		localStorage.removeItem("historyAspirationSelfAppraisal" + (userId || ""));
	}
}

export default Aspirations;
