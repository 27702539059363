import PMS from "@global/components/PMS";

const Initialize = () => {
	PMS.Flows.initialize();
	PMS.Dates.initialize();

	PMS.Log.initialize();
	PMS.Log.info("system", "PMS Initialized");
}

export default Initialize;
