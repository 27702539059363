export default class Collaborator {
    performance = {};
    constructor(params) {
        for (const keys in params) {
            this[keys] = params[keys];
        }
    }

    hasObjectives() {
        return this.performance !== undefined && this.performance.objectives !== undefined;
    }

    hasObjectivesAt(year) {
        const objectives = this.findObjectivesByYear(year);
        return (objectives.objectives && objectives.objectives.length > 0)
    }

    findObjectivesByYear(year) {
        if (!this.hasObjectives()) {
            return [];
        }
        return (this.performance.objectives).find((objectives) => {
            return objectives.year === year;
        }) || {};
    }
}