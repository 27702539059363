const Language = {
	get: () => {
		return localStorage.getItem("language") || "pt-br";
	},
	set: (value) => {
		return localStorage.setItem("language", value);
	}
}

export default Language;
