const listCollaborator = (query) => {
	return `query GetCollaborator(
		$page: Int
		$size: Int
	) {
		listCollaborator(
			page: $page
			size: $size
		){
			isEnding
			collaborators{
				${query}
			}
		}
	}`;
}

export default listCollaborator;
