export default function managerDeletePDP(){
	return `mutation DeleteManagerPDP(
		$appraisee_id: String!
		$_id: String
	) {
		deleteManagerPDP(
			appraisee_id: $appraisee_id
			_id: $_id
		)
	}`;
}
