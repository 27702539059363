const selfAppraisalPDP = () => {
	return `mutation SelfAppraisal(
		$pdps: [PDPAppraisalInput]
	) {
		selfAppraisal(
			pdps: $pdps
		)
	}`;
}

export default selfAppraisalPDP;
