const selfAppraisalObj = () => {
	return `mutation SelfAppraisal(
		$objectives: [AppraisalInput]
	) {
		selfAppraisal(
			objectives: $objectives
		)
	}`;
}

export default selfAppraisalObj;
