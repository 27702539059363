import constants from "../../consts";
import Collaborator from "../Collaborator";

export default class Collaborators {
    constructor(params) {
        this.Collaborators = params || [];
    }

    filterCollaboratorsHasObjectivesIn(year) {
        this.Collaborators = this.Collaborators.filter((collaborator) => {
            collaborator = new Collaborator(collaborator);
            return collaborator.hasObjectivesAt(year);
        });
        return this.Collaborators;
    }

    countIsComplete() {
        return this.Collaborators.filter((collaborator) => {
            return collaborator.status === constants.STATUS_COMPLETE;
        }).length;
    }

    length() {
        return this.Collaborators.length;
    }
}