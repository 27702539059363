const listSubgroupCalibration = () => {
	return `query  {
		hrListCalibrationSubGroup {
			subgroup
			status
			type
			report
			last {
				high
				significant
				contributor
			}
		}
	}`
}

export default listSubgroupCalibration;
