import axios from 'axios';
import PMS from "@global/components/PMS";
import {Auth} from 'aws-amplify';

const client = axios.create({
	baseURL: process.env.REACT_APP_GRAPHQL_URL,
	headers: {
		'Content-Type': 'application/json',
		'authorization': localStorage.getItem("accessToken")
	}
});

client.interceptors.request.use((config) => {
	config.headers.authorization = localStorage.getItem("accessToken");

	return config;
}, (error) => Promise.reject(error));

const Requester = async(obj) => {
	obj.method = "POST";

	try {
		let result = await client(obj);
		return result.data;
	} catch(err) {
		if(err.response.data.errors[0].message === "Token has expired."){
			PMS.Log.warn("axios", "Request retry");
			try{
				await Auth.currentSession();
				let user = await Auth.currentAuthenticatedUser();
				localStorage.setItem("accessToken", user.signInUserSession.accessToken.jwtToken);
				localStorage.setItem("identityToken", user.signInUserSession.idToken.jwtToken);

				let result = await client(obj);

				return result.data;
			} catch(err){
				PMS.Log.error("axios", "Request error", err);
				Promise.reject(err);
			}
		}
		else{
			PMS.Log.error("axios", "Request error", err);
			Promise.reject(err);
		}
	}
}

export default Requester;
