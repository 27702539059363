import PMS from "@global/components/PMS";
import Requester from "@global/components/Requester";
import listFlows from "@global/query/listFlows";
import finishFlow from "@global/query/finishFlow";
import managerFinishFlow from "@global/query/managerFinishFlow";
import HRFinishFlow from "@global/query/HRFinishFlow";

const Flows = {
	get: () => {
		return JSON.parse(localStorage.getItem("flows"));
	},
	getOne: (phase) => {
		return Flows.get()[phase];
	},
	set: (value) =>{
		return localStorage.setItem("flows", JSON.stringify(value));
	},
	verify: (flowName, action) => {
		let flow = PMS.Flows.get()[flowName];
		let today = new Date();
		let start_date = new Date(flow.start_date);
		let end_date = setMaxHours(new Date(flow.end_date));
		if((action === "objectives" && PMS.Cognito.isHR()) || (action === "appraisal" && PMS.Cognito.isHR())){
			return today > start_date;
		}
		else if(action === "self-appraisal" && PMS.Cognito.isHR()) {
			return (today > start_date && today < end_date) || Flows.verify(Flows.appraisal, "appraisal");
		}
		else {
			return today > start_date && today < end_date;
		}
	},
	verifyFlow: (flow, action) => {
		let today = new Date();
		let start_date = new Date(flow.start_date);
		let end_date = setMaxHours(new Date(flow.end_date));
		if((action === "objectives" && PMS.Cognito.isHR()) || (action === "appraisal" && PMS.Cognito.isHR())){
			return today > start_date;
		}
		else if(action === "self-appraisal" && PMS.Cognito.isHR()) {
			return (today > start_date && today < end_date) || Flows.verify(Flows.appraisal, "appraisal");
		}
		else {
			return today > start_date && today < end_date;
		}
	},
	initialize: () => {
		Requester({
			data: {
				query: listFlows()
			}
		}).then((res) => {
			let flows = {}
			const currentFlows = res.data.listFlow.filter(e=>e.year === PMS.Dates.currentYear);
			for(let i = 0; i < currentFlows.length; i++){
				flows[currentFlows[i].type] = currentFlows[i];
			}
			PMS.Flows.set(flows);
		}).catch((err) => {
			PMS.Log.error("FlowsInitialize", err.message);
		});
	},
	getFlowFromData: (data, year, phase) => {
		if(data && data.length){
			return ((data.find((item) => {
				return +item.year === year
			}) || {flows: []}).flows.find((item) => {
				return item.type === phase;
			}) || {pendencies: []});
		}
		else{
			return ({})
		}
	},
	getPendenciesFromData: (data, year, phase) => {
		return Flows.getFlowFromData(data, year, phase).pendencies;
	},
	getPendencyFromData: (data, year, phase, pendency) => {
		return (Flows.getPendenciesFromData(data, year, phase) || []).includes(pendency);
	},
	getFinishFlowQuery: async(profile, id, flowType, pendency, year) => {
		try{
			let action = finishFlow;

			if(PMS.Cognito.isHR()){
				action = HRFinishFlow;
			}
			else if(PMS.Cognito.isManager() && profile !== "myProfile"){
				action = managerFinishFlow;
			}

			let res = await Requester({
				data: {
					query: action(),
					variables: {
						appraisee_id: id || PMS.User.getId(),
						flowType,
						pendency,
						year
					}
				}
			});

			return res.data.hrFlowComplete || res.data.flowComplete || res.data.managerFlowComplete;
		}
		catch(err){
			return {};
		}
	},
	isPassed:(phase)=>{
		let flows = JSON.parse(localStorage.getItem("flows"));
		let flow = flows[phase]
		return new Date() >= new Date(flow.end_date)
	},
	verifyShowAppraisal: (flows, pendency) => {
		if(flows && flows.length){
			return !Flows.getPendencyFromData(flows, PMS.Dates.currentYear, PMS.Flows.self, pendency);
		}
		else{
			return false;
		}
	},
	definition: "Objectives and PDP Definition",
	review: "Objectives and PDP Review",
	middle: "Middle Year Feedback",
	middle_survey: "Middle Year Feedback Survey",
	self: "Self Appraisal",
	appraisal: "Appraisal",
	calibration: "Calibration",
	final: "Final Feedback",
	final_survey: "Final Feedback Survey"
}

const setMaxHours = (date) => {
	date.setHours(23);
	date.setMinutes(59);
	date.setSeconds(59);
	return date;
}

export default Flows;
