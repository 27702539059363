export default function HRdeletePDP(){
	return `mutation HRDeletePDP(
		$_id: String
		$appraisee_id: String!
	) {
		hrDeletePDP(
			_id: $_id
			appraisee_id: $appraisee_id
		)
	}`;
}
