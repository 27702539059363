const selfAppraisalPrinc = () => {
	return `mutation SelfAppraisal(
		$principles: [AppraisalInput]
	) {
		selfAppraisal(
			principles: $principles
		)
	}`;
}

export default selfAppraisalPrinc;
