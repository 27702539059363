const managerFinalFeedback = () => {
	return `mutation ManagerFinalFeedback(
    $appraisal_id: String!
    $manager_comments: String
    $appraisee_comments: String
  ){
    managerFinalFeedback(
      appraisal_id: $appraisal_id
      manager_comments: $manager_comments
      appraisee_comments: $appraisee_comments
    )
  }`;
}

export default managerFinalFeedback;