const saveCalibration = () => {
	return `mutation HRSaveCalibration(
		$_id: String!
		$status: String
		$_9box: String
	) {
		hrSaveCalibration(
			_id: $_id
			status: $status
			_9box: $_9box
		)
	}`
}

export default saveCalibration;
