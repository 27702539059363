import Initialize from "./components/Initialize/";
import Aspirations from "./components/Aspirations";
import Calibration from "./components/Calibration";
import Collaborator from "./components/Collaborator";
import Collaborators from "./components/Collaborators";
import Cognito from "./components/Cognito";
import Config from "./components/Config";
import Dates from "./components/Dates";
import Final from "./components/Final";
import Flows from "./components/Flows";
import Subgroup from "./components/Subgroup";
import Language from "./components/Language";
import Log from "./components/Log";
import Objectives from "./components/Objectives";
import PDP from "./components/PDP";
import URL from "./components/URL";
import User from "./components/User";
import Consts from "./consts";

const PMS = {
	Aspirations,
	Calibration,
	Cognito,
	Config,
	Dates,
	Final,
	Flows,
	Subgroup,
	Language,
	Log,
	Objectives,
	PDP,
	URL,
	User,
	Initialize,
	Collaborator,
	Collaborators, 
	Consts
}

export default PMS;
