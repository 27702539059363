const managerEditPDP = () => {
	return `mutation managerEditPDP(
		$appraisee_id: String!
		$_id: String
		$what_develop: String
		$description: String
		$start_date: String
		$end_date: String
		$title: String
		$current_year: Int
		$category: String
		$principle_id: String
		$repproved_principle: String
		$approved: Boolean
	) {
		managerEditPDP(
			appraisee_id: $appraisee_id
			_id: $_id
			what_develop: $what_develop
			description: $description
			start_date: $start_date
			end_date: $end_date
			title: $title
			current_year: $current_year
			category: $category
			principle_id: $principle_id
			repproved_principle: $repproved_principle
			approved: $approved
		)
	}`;
}

export default managerEditPDP;