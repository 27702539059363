import React, {lazy, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import PrivateRoute from "@global/components/PrivateRoute/";
import PMS from "@global/components/PMS";
import {TourRoot} from "react-rtg";
import {ConfigContext} from './contexts'
import {API} from "aws-amplify";
import {Configuration} from "./models";

const MainPageHR = lazy(() => import("./hr/pages/main"));
const MainPageManager = lazy(() => import("./manager/pages/main"));
const MainPageCollaborator = lazy(() => import("./collaborator/pages/main"));
const Login = lazy(() => import("./login/pages/login"));

export default function App() {

    const [configs, setConfigs] = useState(null);

    PMS.Initialize();

    useEffect(() => {
        API.get("FastApi", "configuration", {}).then(result => {
            setConfigs(new Configuration(result));
        }).catch(console.error)
    }, [API])


    const getCurrentInterface = () => {
        let group = PMS.Cognito.get();
        if (/(head|manager)/.test(group)) {
            return MainPageManager;
        } else if (/human-resources/.test(group)) {
            return MainPageHR;
        } else {
            return MainPageCollaborator;
        }
    }

    return (
        <ConfigContext.Provider value={configs}>
            <TourRoot>
                <BrowserRouter>
                    <Suspense fallback={<div/>}>
                        <Switch>
                            <Route exact path="/login" component={Login}/>
                            <Route path="/recovery" component={Login}/>
                            <Route path="/recovery/:token" component={Login}/>
                            <PrivateRoute path="/" component={getCurrentInterface()}/>
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </TourRoot>
        </ConfigContext.Provider>)
}
