const listSuccession = () => {
	return `query HrSuccessionList(
		$subgroup: String
	) {
		hrSuccessionList(
			subgroup: $subgroup
		){
			image
      name
      key_position
      readiness
      mobility
      medium_term
      long_term
		}
	}`;
}

export default listSuccession;
