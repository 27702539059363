const editAspirations = () => {
	return `mutation EditAspirations(
		$medium_term: String
		$long_term: String
		$mobility: [String]
	) {
		editAspirations(
			medium_term: $medium_term
			long_term: $long_term
			mobility: $mobility
		)
	}`
}

export default editAspirations;
