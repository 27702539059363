import PMS from "@global/components/PMS";
import Requester from "@global/components/Requester";
import sendLogs from "@global/query/sendLogs";

const Log = {
	send: () => {
		let logs = get();
		if(logs.length){
			Requester({
				data: {
					query: sendLogs(),
					variables: {
						global: JSON.stringify({
							email: PMS.User.getEmail(),
							_id: PMS.User.getId(),
						}),
						logs
					}
				}
			}).then(() => {
				console.log("send files");
			}).catch((err) => {
				PMS.Log.error("Log", "Error sending logs!");
			});

			localStorage.setItem("lastLogSent", new Date());
			localStorage.setItem("logs", "[]");
		}
	},
	error: (tag, message, payload) => {
		set("ERROR", tag, message, payload);
	},
	warn: (tag, message, payload) => {
		set("WARN", tag, message, payload);
	},
	info: (tag, message, payload) => {
		set("INFO", tag, message, payload);
	},
	initialize: () => {
		verify();
		setInterval(() => {
			verify();
		}, PMS.Config.logTimeVerification);
	}
}

const verify = () => {
	let lastLogSent = new Date(localStorage.getItem("lastLogSent"));
	lastLogSent.setMinutes(lastLogSent.getMinutes() + PMS.Config.maxLogMinutes);
	if(lastLogSent < new Date()){
		Log.send();
	}
}

const set = (type, tag, message, content) => {
	let logs = get();
	let newLog = {
		type,
		tag,
		message: (PMS.Cognito.isHR ? "HR" : PMS.Cognito.isManager() ? "Manager" : "Collaborator") + " | " + message,
		content: JSON.stringify(content),
		time: new Date()
	}
	logs.push(newLog);
	if(PMS.Config.debug){
		console.log(newLog);
	}
	localStorage.setItem("logs", JSON.stringify(logs));
}

const get = () => {
	return JSON.parse(localStorage.getItem("logs") || "[]");
}

export default Log;
