const Cognito = {
	get: () => {
		return localStorage.getItem("group");
	},
	set: (value) => {
		return localStorage.setItem("group", value);
	},
	isHR: () => {
		return /human-resources/.test(localStorage.getItem("group"));
	},
	isManager: () => {
		return /manager/.test(localStorage.getItem("group"));
	},
	isCollaborator: () => {
		return /collaborator/.test(localStorage.getItem("group"));
	},
}

export default Cognito;
